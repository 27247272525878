.add-alldiagnose {
  form {
    width: 100%;

    .form-control {
      display: flex;
      flex-direction: column;
      width: 50%;
      label {
        color: var(--gray-gray-700, #000);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 4px;
      }

      input {
        font-size: 13px;
        display: flex;
        width: 100%;
        height: 35px;
        padding: 0px 20px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--gray-gray-200, #a7acb3);
        background: var(--black-amp-white-white, #fff);
      }
    }
    .submit {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      button {
        display: flex;
        width: 100px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 0.5px solid #a0aec0;
        background: blue;
        color: #fff;
        text-align: center;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }
}
