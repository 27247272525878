@import "../../styles/variables";
@import "../../styles/media";

.layout_main {
  width: 100%;
  height: 100%;
  display: flex;
  right: 0;
  .moileOpen{
    display: none;
  }
  @include both {
    .moileOpen{
      display: flex;
      inset: 0;
      position: fixed;
      z-index: 99;
    }
  }

  .layout_main_nav {
    overflow-x: hidden;
    height: calc(100% - 60px);
    background-color: #fff;
    border-right: 1px solid rgb(240, 240, 240);
    transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1);
    position: fixed;
    inset: 60px auto 0 0;
    @include both {
      z-index: 100;
    }
    
    
    .title_head {
      transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1);
      position: fixed;
      inset: 0 auto auto 0;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      height: 60px;
      overflow: hidden;
      border-bottom: 1px solid rgb(240, 240, 240);

      background-color: #fff;

      
      img {
        width: 200px;
        object-fit: cover;
        height: 60px;
      }
    }
    .side_navigation {
      box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
      .empty {
        height: 32px;

     
      }
      .side-navbar {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        > ul {
          margin: 0 !important;
          padding: 0 !important;
          user-select: none;
          overflow-x: hidden;

          li {
            > ul {
              overflow: hidden;
              transition: height 0.3s ease;
            }
            .nav-list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 50px;
              padding: 0 15px 0 25px;
              &:hover {
                transition: all 0.3s ease;

                background-color: rgb(230, 244, 255);
              }
              cursor: pointer;
              .nav-list-item {
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: flex-start;

                font-family: Work Sans;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: capitalize;
                margin: 0px;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.57;
                font-family: Inter, sans-serif;
                color: rgb(77, 77, 77);
                .list-icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  padding: 4px;
                  .list-icons {
                    fill: rgb(155, 155, 155);
                    font-size: 50px;
                  }
                }
              }
            }
            .nav-list-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 50px;
              padding: 0 10px 0 30px;
              &:hover {
                transition: all 0.3s ease;
                background-color: rgb(230, 244, 255);
              }
              cursor: pointer;
              .nav-list-item {
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: flex-start;

                font-family: Work Sans;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                padding-left: 30px;
                text-transform: capitalize;
                margin: 0px;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.57;
                font-family: Inter, sans-serif;
                color: rgb(38, 38, 38);
              }
            }
            .nav-list-child-active {
              background-color: rgb(230, 244, 255);
              border-right: 2px solid rgb(22, 119, 255);
              border-top-color: rgb(22, 119, 255);
              border-bottom-color: rgb(22, 119, 255);
              border-left-color: rgb(22, 119, 255);
              color: rgb(22, 119, 255);

              .nav-list-item {
                color: rgb(22, 119, 255);
                .list-icon {
                  .list-icons {
                    fill: rgb(22, 119, 255);
                  }
                }
              }
            }
            .nav-list-active {
              background-color: rgb(230, 244, 255);
              border-right: 2px solid rgb(22, 119, 255);
              border-top-color: rgb(22, 119, 255);
              border-bottom-color: rgb(22, 119, 255);
              border-left-color: rgb(22, 119, 255);
              color: rgb(22, 119, 255);
              .nav-list-item {
                color: rgb(22, 119, 255);
                .list-icon {
                  // background-color: #3182ce;
                  .list-icons {
                    fill: rgb(22, 119, 255);
                  }
                }
              }
            }
            .nav-list-active-text {
              color: rgb(22, 119, 255);
              .nav-list-item {
                color: rgb(22, 119, 255);
                .list-icon {
                  // background-color: #3182ce;
                  .list-icons {
                    fill: rgb(22, 119, 255);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .open_nav {
    width: 260px;
  }
  .close_nav {
    width: 0;
  }
  .layout_main_main {
    flex-grow: 1;
    height: calc(100% - 60px);
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    .open_header {
      width: calc(100% - 260px);
      @include both {
        width: 100%;
      }
    }
    .close_header {
      width: 100%;
    }
    > header {
      height: 60px;
      padding: 5px;
      background-color: #fff;
      border-bottom: 1px solid rgb(240, 240, 240);
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1;
      transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1);
      .layout_main_header {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;
          .menu_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            aspect-ratio: 1;
            border-radius: 3px;
            background: transparent;
            &:hover {
              background: rgb(217, 217, 217);
            }
            svg {
              font-size: 30px;
              stroke-width: 1px;
              color: rgb(38, 38, 38);
            }
          }
          .title_clinic {
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            color: rgb(25, 185, 249);
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 15px;
          height: 100%;

          padding-right: 20px;
          h3 {
            color: rgb(3, 105, 230);
            font-size: 16px;
            text-transform: capitalize;
          }
          .user_logout {
            position: relative;
            ul {
              background-color: #fff;
              width: fit-content;
              position: absolute;
              top: 40px;
              right: 0px;
              li {
                white-space: nowrap;
                padding: 5px 10px;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    > main {
      transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1);
      padding: 10px 20px;
      right: 0;
      @include both {
        padding: 10px;
      }
    }
  }
}
