@import "../../styles/media";

.form__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    font-size: 0.875rem;
    line-height: 1.4375em;
    font-family: Inter, sans-serif;
    font-weight: 400;
    position: relative;
    display: block;
    transform-origin: left top;
    text-overflow: ellipsis;
    max-width: 100%;
    color: rgb(89, 89, 89);
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
  }

  .form__field {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;

    @include mobile {
      font-size: 16px;
    }

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.form__group__textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    font-size: 0.875rem;
            line-height: 1.4375em;
            font-family: Inter, sans-serif;
            font-weight: 400;
            position: relative;
            display: block;
            transform-origin: left top;
            text-overflow: ellipsis;
            max-width: 100%;
            color: rgb(89, 89, 89);
            padding: 0px;
            white-space: nowrap;
            overflow: hidden;
    text-transform: capitalize;
  }

  .form__field {
    display: flex;
    width: 100%;
    min-height: 120px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;
    box-sizing: border-box;

    @include mobile {
      font-size: 16px;
    }

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.form__radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  height: 150px;

  .form__label {
    font-size: 0.875rem;
    line-height: 1.4375em;
    font-family: Inter, sans-serif;
    font-weight: 400;
    position: relative;
    display: block;
    transform-origin: left top;
    text-overflow: ellipsis;
    max-width: 100%;
    color: rgb(89, 89, 89);
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    /* 19.6px */
  }

  .form__field {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }
}

.radio_yes_no {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  > label {
    font-size: 14px;
    font-weight: 500;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 20px;

    .radios {
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .radio__input {
      input {
        border-radius: 5px;
        border: 1px solid #3182ce;
        background: var(--black-amp-white-white, #fff);
        width: 120%;
        height: 45px;
        flex-shrink: 0;
        padding-left: 10px;

        @include tablet {
          font-size: 16px;
          width: calc(100% - 20px);
          height: 30px;
        }
        @include mobile {
          font-size: 16px;
          width: calc(100% - 20px);
          height: 30px;
        }
      }
    }
  }
}

.form__date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    font-size: 0.875rem;
    line-height: 1.4375em;
    font-family: Inter, sans-serif;
    font-weight: 400;
    position: relative;
    display: block;
    transform-origin: left top;
    text-overflow: ellipsis;
    max-width: 100%;
    color: rgb(89, 89, 89);
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
  }

  .date_select {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;

    .contant {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      label {
        font-size: 0.875rem;
        line-height: 1.4375em;
        font-family: Inter, sans-serif;
        font-weight: 400;
        position: relative;
        display: block;
        transform-origin: left top;
        text-overflow: ellipsis;
        max-width: 100%;
        color: rgb(89, 89, 89);
        padding: 0px;
        white-space: nowrap;
        overflow: hidden;
      }

      select {
        display: flex;
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid var(--gray-gray-200, #e2e8f0);
        background: var(--black-amp-white-white, #fff);
        font-size: 14px;
      }
    }
  }
}

.input_webcamImage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  label {
    font-size: 0.875rem;
            line-height: 1.4375em;
            font-family: Inter, sans-serif;
            font-weight: 400;
            position: relative;
            display: block;
            transform-origin: left top;
            text-overflow: ellipsis;
            max-width: 100%;
            color: rgb(89, 89, 89);
            padding: 0px;
            white-space: nowrap;
            overflow: hidden;
  }

  .from_file {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .selection_option {
    display: flex;
    gap: 10px;
    height: 80px;

    .from_webcam {
      display: flex;
      flex-direction: column;

      > div {
        img {
          height: 40px;
          width: 40px;
          cursor: pointer;
        }
      }
    }
  }

  .image_contaier {
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.web_cam {
  position: fixed;
  inset: 100px;
  display: flex;

  justify-content: center;
  align-items: center;

  .cam_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 600px;
    width: 600px;

    .takebtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: gray;
      border: 1px solid black;
      position: absolute;
      bottom: 100px;
      cursor: pointer;
    }
  }
}

.form_search {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-size: 0.875rem;
    line-height: 1.4375em;
    font-family: Inter, sans-serif;
    font-weight: 400;
    position: relative;
    display: block;
    transform-origin: left top;
    text-overflow: ellipsis;
    max-width: 100%;
    color: rgb(89, 89, 89);
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
  }

  .search_select {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;
    justify-content: space-between;
    cursor: pointer;
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  ul {
    position: absolute;
    inset: 60px 0 0 0;
    z-index: 100;
    height: 300px;
    overflow-y: auto;

    li {
      padding: 10px;
      background-color: white;
      font-size: 14px;
      border-bottom: 1px solid gray;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: rgb(80, 169, 241);
      }

      &:has(input) {
        padding: 0px !important;
      }

      > input {
        display: flex;
        width: 100%;
        height: 30px;
        align-items: center;
        padding: 0px 10px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid var(--gray-gray-200, #e2e8f0);
        background: var(--black-amp-white-white, #fff);
        font-size: 14px;
      }
    }
  }
}

.PhoneInputInput {
  display: flex;
  height: 40px;

  padding: 0px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--gray-gray-200, #e2e8f0);
  background: var(--black-amp-white-white, #fff);
  font-size: 14px;
}

.phoneinput {
  width: 100%;
}

.form__label__none {
  text-transform: none !important;
}
