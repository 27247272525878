@import "../../../../styles/media";

.treatment-list {
  .header {
    color: var(--gray-gray-700, #2d3748);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;

    thead {
      display: table-header-group;
      background-color: rgb(250, 250, 250);
      border-top: 1px solid rgb(240, 240, 240);
      border-bottom: 2px solid rgb(240, 240, 240);
      tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0px;
        th {
          line-height: 1.5rem;
          font-family: Inter, sans-serif;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
          &:first-child {
            text-align: start;
          }
          &:last-child {
            text-align: end;
          }
        }
      }
    }

    tbody {
      tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0px;
        th {
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
        }
        td {
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
          @include mobile {
            padding: 5px 10px;
            padding-left: 50%;
            font-weight: 500;
            font-size: 12px;
            min-height: 20px;
          }
          &:first-child {
            text-align: start;
          }
          &:nth-child(4) {
            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              div {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: rgb(6, 155, 73);
              }
              p{
                font-size: 0.875rem;
                line-height: 1.57;
                font-family: "Public Sans", sans-serif;
                font-weight: 400;
                margin-left: 8px;
              }
            }
          }
          &:last-child {
            text-align: end;
            width: 120px;
            @include mobile {
              white-space: nowrap;
            }
            button {
              margin-right: 10px;
              background-color: #174d7a;

              padding: 5px;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              &:last-child {
                background-color: green;
              }
            }
          }
        }
      }
    }
  }
  @include mobile {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block !important;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin-bottom: 5px;
      border: 1px solid #ddd;
    }

    td {
      border: none;
      position: relative;
      text-align: end;
      color: red;
      width: 100%;
      padding: 12px;
    }

    td:before {
      position: absolute;
      left: 6px;
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
.treatment-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  background-color: #fff;

  @include mobile {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .form-search {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--Gray-Gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    width: 255px;
    height: 30px;
    padding-left: 13px;
    input {
      outline: none;
      border: none;
      font-size: 13px;
      &::placeholder {
        color: var(--Gray-Gray-400, #a0aec0);
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .treatment-check {
    display: flex;
    align-items: center;
    gap: 20px;
    .form-check {
      display: flex;
      align-items: center;
      gap: 15px;
      input {
        display: none;
      }
      label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25;
        max-width: 360px;
        min-width: 90px;
        position: relative;
        flex-shrink: 0;
        padding: 9px 16px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        flex-direction: row;
        min-height: 46px;
        color: rgb(38, 38, 38);
        border-radius: 4px;
      }
      input:checked ~ label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25;
        max-width: 360px;
        min-width: 90px;
        position: relative;
        flex-shrink: 0;
        padding: 9px 16px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        flex-direction: row;
        min-height: 46px;
        color: rgb(22, 119, 255);
        border-radius: 4px;
        border-bottom: 1px solid rgb(22, 119, 255);
      }
    }
  }
}
