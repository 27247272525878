@import "../../../../styles/media";


.view_clinic {

  @include both {
    padding:10px;

  }
  .addClinicbtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
   button{
    background-color: #174d7a;

    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
   }

  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headTxt {
      color: var(--gray-gray-700, #2d3748);
      font-family: Work Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
    }
    .table_heading {
      color: #3182ce;
      font-family: Work Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .table_view_patient {

    table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0px;
      thead {
        display: table-header-group;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(240, 240, 240);
        border-bottom: 2px solid rgb(240, 240, 240);

        tr {
          color: inherit;
          display: table-row;
          vertical-align: middle;
          outline: 0px;
          th {
            line-height: 1.5rem;
            font-family: Inter, sans-serif;
            display: table-cell;
            vertical-align: inherit;
            border-bottom: 1px solid rgb(240, 240, 240);
            text-align: left;
            color: rgb(38, 38, 38);
            padding: 12px;
            border-top-color: rgb(240, 240, 240);
            border-right-color: rgb(240, 240, 240);
            border-left-color: rgb(240, 240, 240);
            font-size: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;
          &:first-child {
            text-align: start;
          }
          &:last-child {
            text-align: end;
          }
        }
        border-bottom: 1px solid #000;
      }
    }

    tbody {
      tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0px;
        th{
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
        }
        td {
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
          @include mobile {
            padding: 5px 10px;
            padding-left: 50%;
            font-weight: 500;
            font-size: 12px;
            min-height: 20px;
          }
          &:first-child {
            text-align: start;
          }
          &:last-child {
            text-align: end;
            width: fit-content;
            padding-right: 0;

            button {
              margin-right: 10px;
              background-color: #174d7a;

              padding: 5px;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              &:first-child {
                background-color: red;
              }
              &:last-child {
                background-color: #02c58d;
              }
            }
          }
        }
      }
    }
    }
    @include mobile {
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block !important;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        margin-bottom: 5px;
        border: 1px solid #ddd;
      }

      td {
        border: none;
        position: relative;
       
      }

      td:before {
        position: absolute;
        left: 6px;
        content: attr(data-label);
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
