.invoice-treatment {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow-y: scroll;
  padding: 10px;

  .main-modal {
    margin-top: auto;
    margin-bottom: auto;
    width: 350px;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
    .header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .select_check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px 0;
    }
    .form-check {
      display: flex;
      align-items: center;
      font-weight: 500;
      width: 50%;
      input {
        display: none;
      }

      label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25;
        min-width: 90px;
        position: relative;
        flex-shrink: 0;
        padding: 4px 16px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        flex-direction: row;
        color: rgb(38, 38, 38);
      }

      input:checked ~ label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25;
        min-width: 90px;
        position: relative;
        flex-shrink: 0;
        padding: 4px 16px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        flex-direction: row;
        color: rgb(22, 119, 255);

        border-bottom: 1px solid rgb(22, 119, 255);
      }
    }
    .form {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .tooth-select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 5px;

        > label {
          font-size: 13px;
          text-transform: capitalize;
        }
        .dropdown {
          width: 100%;
          cursor: pointer;
          position: relative;
          .titles {
            width: 100%;
            height: 30px;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-radius: 5px;
          }
          ul {
            position: absolute;
            left: 0;
            right: 0;
            top: 30px;
            padding: 0;
            max-height: 180px;
            overflow-y: scroll;
            li {
              background-color: white;
              border-bottom: 1px solid gray;
              padding: 5px 2px;
              font-size: 13px;
              border-radius: 2px;
              background: #fff;
              box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
            }
          }
        }
        input {
          width: 100%;
          height: 40px;
          border-radius: 5px;
          border: 1px solid gray;
          padding-left: 5px;
        }
      }
      
      .form-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100%);
        gap: 5px;
        label {
          font-size: 13px;
          text-transform: capitalize;
        }
        select,
        input {
          width: calc(100%);
          height: 30px;
          border-radius: 5px;
          border: 1px solid #ccc;
          padding-left: 5px;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          color: red;
        }
      }
      .form_treatmentdrop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 5px;
        position: relative;
        label {
          font-size: 13px;
          text-transform: capitalize;
        }
        .default {
          width: calc(100%);
          height: 30px;
          border-radius: 5px;
          font-size: 13px;
          border: 1px solid #ccc;
          padding-left: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 600;
        }
        ul {
          position: absolute;
          top: 50px;
          background-color: rgb(255, 255, 255);
          width: 100%;
          li {
            padding: 5px;
            font-size: 13px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;
            border-bottom: 0.5px solid rgb(174, 174, 174);
          }
        }
      }
      button {
        height: 30px;
        width: 100%;
        padding: 5px 10px;
        background-color: blue;
        color: white;
        border-radius: 5px;
        font-size: 13px;
      }
    }
    .header {
      width: 100%;
      text-align: end;
      cursor: pointer;
    }
  }
}
