.payment_reminder {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .container {
    width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    padding: 15px 10px;
    background-color: rgb(255, 255, 255);
    color: rgb(38, 38, 38);
    box-shadow:
      rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
      rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
      rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 768px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 4px;
    h2 {
      text-align: center;
      line-height: 1.57;
      font-family: Inter, sans-serif;
      font-size: 1.5rem;
      font-weight: 600;
      color: rgb(38, 38, 38);
    }
    p {
      padding: 10px;
      text-align: start;
      margin: 0px;
      font-size: 0.875rem;
      line-height: 1.57;
      font-family: Inter, sans-serif;
      font-weight: 400;
      display: block;
    }
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-transform: capitalize;
      font-family: Inter, sans-serif;
      font-size: 0.875rem;
      line-height: 1.75;
      min-width: 64px;
      color: rgb(255, 255, 255);
      background-color: rgb(22, 119, 255);
      box-shadow: none;
      font-weight: 400;
      outline: 0px;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      margin: 0px;
      text-decoration: none;
      padding: 6px 16px;
      border-radius: 4px;
      transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
        color 250ms cubic-bezier(0.4, 0, 0.2, 1);
      &:hover {
        background-color: rgb(9, 88, 217);
      }
    }
  }
}
