.payment-list-container {
  .header {
    color: #2d3748;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  > table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;

    thead {
      display: table-header-group;
      background-color: rgb(250, 250, 250);
      border-top: 1px solid rgb(240, 240, 240);
      border-bottom: 2px solid rgb(240, 240, 240);

      tr {
        color: inherit;
          display: table-row;
          vertical-align: middle;
          outline: 0px;
        th {
          line-height: 1.5rem;
          font-family: Inter, sans-serif;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
          &:first-child {
            text-align: start;
          }
          &:last-child {
            text-align: end;
          }
        }
        border-bottom: 1px solid #000;
      }
    }

    > tbody {
      > tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0px;
        th{
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
        }
        td {
          line-height: 1.66;
          font-family: Inter, sans-serif;
          font-weight: 400;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 1px solid rgb(240, 240, 240);
          text-align: left;
          color: rgb(38, 38, 38);
          font-size: 0.875rem;
          padding: 12px;
          border-top-color: rgb(240, 240, 240);
          border-right-color: rgb(240, 240, 240);
          border-left-color: rgb(240, 240, 240);
         
          &:first-child {
            text-align: start;
          }
          &:last-child {
            text-align: end;
            width: 120px;
            button {
              margin-right: 10px;
              background-color: #174d7a;

              padding: 5px;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              &:last-child {
                background-color: green;
              }
            }
          }
          // start
          > table {
            width: 100%;
            border-collapse: collapse;

            tbody {
              > tr {
                cursor: pointer;
                td {
                  text-align: start;
                  text-transform: capitalize;
                  font-weight: 500;
                  width: 100px;

                  font-size: 13px;
                  background-color: #fff;
                  color: var(--gray-gray-500, #718096);
                  font-family: Work Sans;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  color: #000;
                  padding: 0;
                  &:first-child {
                    text-align: start;
                  }
                  &:last-child {
                    text-align: end;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.paymentSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border: 0.5px solid #3182ce;
  height: 30px;
  padding: 0 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 200px;

  input {
    border: none;
    outline: none;
    background: transparent;
    height: 30px;
    width: 180px;
    &::placeholder {
      color: #3182ce;
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
.row_form {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;
  .form__group {
    gap: 2px;
    margin: 0;
    input {
      height: 30px;
    }
  }
  .grid {
    display: flex;
    gap: 10px;
  }
  button {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 30px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: rgb(41, 39, 39);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--gray-700, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
  }
  .generate_button {
    background: rgb(250, 163, 0);
    padding: 5px;
    font-size: 10px;
    white-space: nowrap;
  }
}
