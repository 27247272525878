@import "../../styles/variables";
.common-layout {
  position: relative;
  @media screen and (max-width: $tab) {
    padding: 8px 5px 5px 5px;
  }
  > p {
    color: rgb(105, 105, 105);
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 140%;
    text-transform: capitalize;
    margin-bottom: 5px;
    @media screen and (max-width: $tab) {
      display: none;
    }
  }
}
