.bottomtab {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.bottomtabbutton {
  display: flex;
}
.bottomtabbutton button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: 0.5px solid rgb(209, 208, 208);
  border-left: none;
  color: black;
  background-color: white;
}
.bottomtabbutton button:first-child {
  border-left: 0.5px solid rgb(209, 208, 208);
  border-radius: 4px 0 0 4px;
}
.bottomtabbutton button:last-child {
  border-radius: 0 4px 4px 0;
}
.bottomtab .btnActive {
  background-color: #4763e4 !important;
  border: none !important;
  color: white !important;
}
.bottomtab span {
  color: #4763e4;
}
