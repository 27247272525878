.custom_layout {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-top: 50px;
    #openSidebarnew:checked ~ #newDrawer {
        background-color: #752525 !important;
    }
    .nav_layout {
        position: fixed;
        inset: 0 0 auto 0;
        background-color: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
        padding: 5px;

        .humber_icon {
            height: 100%;
            display: flex;
            align-items: center;
            aspect-ratio: 1;
            justify-content: center;
            background-color: #c0c0c0;
            overflow: hidden;
            border-radius: 5px;

            > input {
                // display: none;
            }

            svg {
                font-size: 30px;
                color: rgb(0, 89, 255);
            }
        }

        .topNav {
            header {
                .title {
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(0, 89, 255);
                    margin-left: 10px;
                }
            }
        }
    }

    .main {
        min-height: calc(100%);
        width: 100%;
        background-color: rgb(209, 233, 246);
        display: flex;
        .drawer {
            width: 200px;
            overflow: hidden;
            min-height: calc(100vh - 50px);
            background-color: #fff;
            transition: width 1s;
            box-shadow:
                rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
                rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
            .main_nav {
                width: 200px;
                padding-top: 20px;

                > li {
                    width: 200px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .nav_item {
                        padding: 5px 10px;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        border-right: 2px solid rgb(0, 89, 255);
                        background-color: rgba(0, 89, 255, 0.041);
                        position: relative;

                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20%;

                            svg {
                                color: rgb(0, 89, 255);
                            }
                        }

                        .drop_icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 10%;

                            svg {
                                color: rgb(0, 89, 255);
                            }
                        }

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 70%;
                            color: rgb(0, 89, 255);
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .sub_nav {
                width: 100%;
                padding-left: 10px;

                > li {
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .nav_item {
                        padding: 5px 10px;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        border-right: 2px solid rgb(0, 89, 255);
                        background-color: rgba(0, 89, 255, 0.041);

                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20% !important;

                            svg {
                                color: rgb(0, 89, 255);
                            }
                        }

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 70%;
                            color: rgb(0, 89, 255);
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .newContainer {
         flex: 1;
        }
    }
}
