@import "../../styles/media";

.form__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    color: var(--gray-gray-700, #000);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: capitalize;
  }

  .form__field {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;

    @include mobile {
      font-size: 16px;

    }

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
.form__group__check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    color: var(--gray-gray-700, #000);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: capitalize;
  }

  .form__field {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;

    @include mobile {
      font-size: 16px;

    }

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.form__group__textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    color: var(--gray-gray-700, #000);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: capitalize;
  }

  .form__field {
    display: flex;
    width: 100%;
    min-height: 120px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-gray-200, #e2e8f0);
    background: var(--black-amp-white-white, #fff);
    font-size: 14px;
    box-sizing: border-box;

    @include mobile {
      font-size: 16px;

    }

    &::placeholder {
      color: var(--gray-gray-400, #a0aec0);
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }

  .mandatory_input {
    border: 1px solid var(--gray-gray-200, red) !important;

    &::placeholder {
      color: var(--gray-gray-400, red);
    }
  }

  .form__label__error {
    color: var(--gray-gray-700, red);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}



.radio_yes_no {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  >label {
    font-size: 14px;
    font-weight: 500;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 20px;

    .radios {
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .radio__input {
      input {
        border-radius: 5px;
        border: 1px solid #3182ce;
        background: var(--black-amp-white-white, #fff);
        width: 120%;
        height: 45px;
        flex-shrink: 0;
        padding-left: 10px;

        @include tablet {
          font-size: 16px;
          width: calc(100% - 20px);
          height: 30px;

        }
        @include mobile {
          font-size: 16px;
          width: calc(100% - 20px);
          height: 30px;

        }
      }
    }
  }
}

.form__date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin: 5px 0;

  .form__label {
    color: var(--gray-gray-700, #2d3748);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .date_select {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;

    .contant {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      label {
        color: var(--gray-gray-700, #2d3748);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      select {
        display: flex;
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid var(--gray-gray-200, #e2e8f0);
        background: var(--black-amp-white-white, #fff);
        font-size: 14px;
      }
    }
  }
}
