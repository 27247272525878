@import "../../../../styles/media";
table.dtable {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  thead {
    display: table-header-group;
    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 2px solid rgb(240, 240, 240);

    tr {
      color: inherit;
      display: table-row;
      vertical-align: middle;
      outline: 0px;
      th {
        line-height: 1.5rem;
        font-family: Inter, sans-serif;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgb(240, 240, 240);
        text-align: left;
        color: rgb(38, 38, 38);
        padding: 12px;
        border-top-color: rgb(240, 240, 240);
        border-right-color: rgb(240, 240, 240);
        border-left-color: rgb(240, 240, 240);
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        @include both {
          font-size: 12px;
          // padding: 5px;
        }
        &:first-child {
          text-align: start;
        }
        &:last-child {
          text-align: end;
        }
      }
      border-bottom: 1px solid #000;
    }
  }

  tbody {
    tr {
      color: inherit;
          display: table-row;
          vertical-align: middle;
          outline: 0px;
          th{
            line-height: 1.66;
            font-family: Inter, sans-serif;
            font-weight: 400;
            display: table-cell;
            vertical-align: inherit;
            border-bottom: 1px solid rgb(240, 240, 240);
            text-align: left;
            color: rgb(38, 38, 38);
            font-size: 0.875rem;
            padding: 12px;
            border-top-color: rgb(240, 240, 240);
            border-right-color: rgb(240, 240, 240);
            border-left-color: rgb(240, 240, 240);
          }
          td {
            line-height: 1.66;
            font-family: Inter, sans-serif;
            font-weight: 400;
            display: table-cell;
            vertical-align: inherit;
            border-bottom: 1px solid rgb(240, 240, 240);
            text-align: left;
            color: rgb(38, 38, 38);
            font-size: 0.875rem;
            padding: 12px;
            border-top-color: rgb(240, 240, 240);
            border-right-color: rgb(240, 240, 240);
            border-left-color: rgb(240, 240, 240);
          
        @include mobile {
          padding: 5px 10px;
          padding-left: 50%;
          font-weight: 500;
          font-size: 12px;
          min-height: 20px;
        }
        &:nth-child(2) {
          max-width: 120px;
          overflow: hidden;
          @include mobile {
            max-width: 100%;
          }
        }
        @include tablet {
          font-size: 11px;
          padding: 5px;
        }
        &:first-child {
          text-align: start;
        }

        &:last-child {
          text-align: end;
          width: 180px;
          padding-right: 0;
          white-space: nowrap;

          button {
            margin-right: 10px;
            background-color: #174d7a;
            padding: 5px;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            text-transform: uppercase;
            @include mobile {
              margin-right: 5px;
            }
            @include both {
              font-size: 13px;
              padding: 3px 5px;
            }
          }
          .edit {
            background-color: #174d7a;
          }
          .addto {
            background-color: #02c58d;
          }
          .delete {
            background-color: red;
          }
        }
      }
    }
  }
}
@include mobile {
  table.dtable {
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
  }
  table.dtable{
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr {
      margin-bottom: 5px;
      border: 1px solid #ddd;
    }
  
    td {
      border: none;
      position: relative;
    }
  
    td:before {
      position: absolute;
      left: 6px;
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
    }
  }

 
}
