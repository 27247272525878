.editClinic {
  width: 100%;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headTxt {
      color: #3182ce;
      font-family: Work Sans;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .clinicAction {
    width: 100%;
    border-collapse: collapse;
    tbody {
      tr {
        td {
          padding: 8px;
          &:first-child {
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
          }
          &:last-child {
            text-align: end;
            button {
              background-color: #174d7a;

              padding: 8px 10px;
              border-radius: 4px;
              font-size: 14px;
              color: #fff;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
              color: red;
            }
            &:last-child {
              text-align: end;
              button {
                background-color: red;

                padding: 8px 10px;
                border-radius: 4px;
                font-size: 14px;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
@import ".../../../../../../styles/media";

.edidClinicModal {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  vertical-align: center;
  z-index: 1000;
  overflow-y: scroll;
  padding: 20px;
  @include both {
    padding: 10px 0px;
  }

  .container {
    width: 500px;
    border-radius: 5px;
    height: max-content;
    background: var(--black-amp-white-white, #fff);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;margin-top: auto;
    margin-bottom: auto;
    @include both {
      padding: 10px;
      height: fit-content;
    }
    @include mobile {
      width: calc(100% - 10px);
    }
    header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .formgrid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 20px;
      row-gap: 20px;
      .slidelogoContainer {
        height: 150px;
      }
    }
    .logo-images {
      display: flex;
      margin: 10px 0;
      > label {
        border: 1px dotted gray;
        overflow: hidden;
        height: 85px;
        width: 260px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        object-fit: contain;

        img {
          display: block;
          height: 100%;
          width: 100% !important;
          object-fit: contain;
        }
        > label {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
    .print-image {
      display: flex;
      margin: 10px 0;
      > label {
        border: 1px dotted gray;
        overflow: hidden;
        height: 85px;
        width: 260px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        object-fit: contain;

        img {
          display: block;
          height: 100%;
          width: 100% !important;
          object-fit: contain;
        }
        > label {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
    button {
      margin: 20px 0;
      width: 48%;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: rgb(41, 39, 39);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--gray-700, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      @include mobile {
        width: calc(100% - 10px);
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
}
