
@import "../../../../../styles/media";

.addtreatement {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .doctor-select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      @include mobile {
    display: none;
      }
      label {
        color: #3182ce;
        font-family: Work Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        text-transform: uppercase;
      }
      .select-box {
        border-radius: 5px;
        width: fit-content;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 17px;
        font-size: 13px;
        color: var(--Gray-Gray-400, #a0aec0);
        font-family: Work Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        cursor: pointer;
        position: relative;
        ul {
          position: absolute;
          top: 30px;
          background-color: #fff;
          width: 100%;
          left: 0;
          li {
            border-bottom: 1px solid #3182ce;
            cursor: pointer;
            color: #000;
            padding: 10px 17px;
          }
        }
      }
    }
    .treatment-routes {
      display: flex;
      align-items: center;
      gap: 20px;
      @include mobile {
        gap: 5px;
      }
      a {
        color: var(--Gray-Gray-700, #2d3748);
        text-align: center;
        font-family: Work Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
        text-transform: uppercase;
        border-radius: 8px;

        min-width: 150px;
        padding: 0 20px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        @include mobile {
          padding: 0 10px;
          width: fit-content;
          min-width: auto;
          height: 20px;
          font-size: 11px;
          border-radius: 5px;
        }
      }
      .nav-list-active {
        box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06);
        color: #fff;
        background-color: #174d7a;
      }
    }
  }