.manage_purpose {
    table {
      display: table;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0px;
        thead {
          display: table-header-group;
          background-color: rgb(250, 250, 250);
          border-top: 1px solid rgb(240, 240, 240);
          border-bottom: 2px solid rgb(240, 240, 240);
  
          tr {
            color: inherit;
            display: table-row;
            vertical-align: middle;
            outline: 0px;
            th {
              line-height: 1.5rem;
              font-family: Inter, sans-serif;
              display: table-cell;
              vertical-align: inherit;
              border-bottom: 1px solid rgb(240, 240, 240);
              text-align: left;
              color: rgb(38, 38, 38);
              padding: 12px;
              border-top-color: rgb(240, 240, 240);
              border-right-color: rgb(240, 240, 240);
              border-left-color: rgb(240, 240, 240);
              font-size: 0.75rem;
              font-weight: 700;
              text-transform: uppercase;
            &:first-child {
              text-align: start;
            }
            &:last-child {
              text-align: end;
            }
          }
        }
      }
  
      tbody {
        tr {
          color: inherit;
          display: table-row;
          vertical-align: middle;
          outline: 0px;
          th{
            line-height: 1.66;
            font-family: Inter, sans-serif;
            font-weight: 400;
            display: table-cell;
            vertical-align: inherit;
            border-bottom: 1px solid rgb(240, 240, 240);
            text-align: left;
            color: rgb(38, 38, 38);
            font-size: 0.875rem;
            padding: 12px;
            border-top-color: rgb(240, 240, 240);
            border-right-color: rgb(240, 240, 240);
            border-left-color: rgb(240, 240, 240);
          }
          td {
            line-height: 1.66;
            font-family: Inter, sans-serif;
            font-weight: 400;
            display: table-cell;
            vertical-align: inherit;
            border-bottom: 1px solid rgb(240, 240, 240);
            text-align: left;
            color: rgb(38, 38, 38);
            font-size: 0.875rem;
            padding: 12px;
            border-top-color: rgb(240, 240, 240);
            border-right-color: rgb(240, 240, 240);
            border-left-color: rgb(240, 240, 240);
            white-space: nowrap;
           
            &:first-child {
              text-align: start;
            }
            &:last-child {
              text-align: end;
              padding-right: 0;
  
              width: 120px;
              button {
                margin-right: 5px;
  
                padding: 5px;
                border-radius: 4px;
                font-size: 0.75rem;
                color: #fff;
                text-transform: uppercase;
                font-weight: 400;
                background-color: transparent;
                &:first-child {
                  svg {
                    color: rgb(9, 88, 217);
                  }
                  &:hover {
                    background-color: #174d7a46;
                  }
                }
                &:last-child {
                  svg {
                    color: red;
                  }
                  &:hover {
                    background-color: rgba(255, 0, 0, 0.277);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  