@import "../../styles/media";

.commonmodal {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
  padding: 20px;
  background-color: rgb(0, 0, 0, 0.3);

  @include both {
    padding: 10px;
  }
  .container {
    margin-top: auto;
    margin-bottom: auto;
    width: 500px;
    border-radius: 10px;
    height: max-content;
    background: var(--black-amp-white-white, #fff);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    @include mobile {
      width: calc(100% - 10px);
    }
    header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .grid {
      display: flex;
      gap: 10px;
    }
    button {
      margin: 20px 0 0 0;
      width: 170px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: rgb(41, 39, 39);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--gray-700, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
    }
    .generate_button {
      width: 100%;
      background: rgb(250, 163, 0);
    }
  }
}
.sub_button {
  margin: 20px 0;
  width: 170px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: rgb(41, 39, 39);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
  @include mobile {
    width: calc(100%);
    font-size: 14px;
    padding: 3px;
  }
}
