@import "./styles/media";
@import "./styles/variables";
@import "./client/pages/staff/employee/employees/employee.scss";
@import "./client/pages/payment/payment.scss";
@import "./client/pages/manage/alltreatments/addtreatment/addtretment.scss";
@import "./client/pages/examinations/viewTreatments/treatmentlist.scss";
@import "./client/pages/manage/managediagnosis/adddiagnose/addalldiagnose.scss";
@import "./client/pages/payment/invoice/components/invoicetreatment.scss";
@import "./components/inputs/input.scss";
@import "./components/formFeild/forminput.scss";
@import "./client/pages/inventory/scale/scale.scss";
@import "./client/pages/inventory/purchase/purchase.scss";
@import "./client/pages/setting/managepurpose/purpose.scss";
@import "./admin/pages/clinic/allclinic/allclinic.scss";
@import "./admin/pages/clinic/editClinic/editClinic.scss";
@import 'react-phone-number-input/style.css';
body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: #f3f4f7;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}
/* body::-webkit-scrollbar {
  display: none;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inter !important;
  user-select: none;
}

@include desktop {
  body {
    overflow-y: inherit;
  }
}


::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
p {
  margin: 0;
}
a {
  text-decoration: none !important;
}
svg {
  cursor: pointer;
}
button {
  cursor: pointer;
  border: none;
}
#dental {
  width: 100%;
  height: 100%;
}
ul {
  list-style: none;
}
li {
  cursor: pointer;
  user-select: none;
}
input,
textarea,
select {
  outline: none;
}
select {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.endDot::after {
  content: "*";
  color: red;
}
.grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
